import { Card, Col, ListGroup, Row } from "react-bootstrap";
import axios from "axios";
import {Link} from 'react-router-dom';
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { GlobalSet } from '../components/global/GlobalProvider';


const Footer = () => {
  const { t } = useTranslation();
  var d = new Date();

  const { BaseUrl } = GlobalSet();
  var home_menu = BaseUrl + "public/appssource/sports_icons/home_menu.png";
  var casino_menu = BaseUrl + "public/appssource/sports_icons/casino_menu.png";
  var sports_menu = BaseUrl + "public/appssource/sports_icons/sports_menu.png";
  var bonus_menu = BaseUrl + "public/appssource/sports_icons/bonus_menu.png"; 
   
  return (
    <div className="footer m-4 text-black">
      <div style={{textAlign:'center'}} className="copyright">
      <Row>
        <Col  className="col-xl-6 col-xxl-6 col-sm-6">
         
            <Card.Body>
            <Row>
              <Col  className="col-xl-6 col-xxl-6 col-sm-6">
                  <div className="basic-list-group">
                    <li as="ul">
                        <a href="https://affiliate.betfanda.com/" target="_blank" rel="noopener noreferrer">
                          <li className="mb-2 text-black">{t('pro_footer_slot_dic')}</li>
                        </a>
                        <a href="https://agent.betfanda.com/" target="_blank" rel="noopener noreferrer">
                          <li className="mb-2 text-black">Agent</li>
                        </a>
                        <Link to={"/view-page/about-us"}>
                         <li className="mb-2 text-black"> {t('pro_footer_about_us')}</li>
                        </Link>
                        <Link to={"/view-page/Terms-and-conditions"}>
                         <li className="mb-2 text-black"> {t('pro_footer_trms_con')}</li>
                        </Link>
                        <Link to={"/view-page/privacy-policy"}>
                         <li className="mb-2 text-black"> {t('pro_footer_pr_po')}</li>
                        </Link>
                    </li>
                  </div>
              </Col>
              <Col  className="col-xl-6 col-xxl-6 col-sm-6">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <Link to={"/sportsbook"}>
                        <li className="mb-2 text-black"> {t('pro_footer_lv_gm')} </li>
                        </Link>     
                        <Link to={"/sportsbook?bt-path=%2Fsoccer-1"}>                   
                        <li className="mb-2 text-black"> {t('pro_footer_foo_bl')} </li>
                        </Link>  
                        <Link to={"/sportsbook?bt-path=%2Fbasketball-2"}>
                        <li className="mb-2 text-black"> {t('pro_footer_bask_bl')} </li>
                        </Link>  
                    </li>
                  </div>
              </Col>
            </Row>
            </Card.Body>
        </Col>
        <Col  className="col-xl-6 col-xxl-6 col-sm-6">
            <Card.Body>
            <Row>
              <Col  className="col-xl-6 col-xxl-6 col-sm-6">
                  <div className="basic-list-group text-black">
                    <li as="ul">
                        <Link to={"/home-game/all-game"}>
                        <li className="mb-2 text-black"> {t('pro_footer_casino')}  </li>
                        </Link>  
                        <Link to={"/home-game/live-casino"}>
                        <li className="mb-2 text-black"> {t('pro_footer_lv_cas')} </li>
                        </Link>  
                        <Link to={"/home-game/table-game"}>
                        <li className="mb-2 text-black"> {t('pro_footer_tv_gm')} </li>
                        </Link>  
                    </li>
                  </div>
              </Col>
              <Col  className="col-xl-6 col-xxl-6 col-sm-6">
                  <div className="basic-list-group  text-black">
                    <li as="ul">
                        <li className="mb-2"> {t('pro_footer_bt_res')} </li>
                        <li className="mb-2">  {t('pro_footer_bt_info')} </li>
                        <li className="mb-2">{t('pro_footer_res_gm')} </li>
                    </li>
                  </div>
              </Col>
            </Row>


            </Card.Body>
        </Col>
      </Row>

        <p>
          {t('pro_footer_copyright')}
         {/* {d.getFullYear()}*/}
        </p>
      </div>



        <footer className="mobilefutshow">
            <div className="footer-nav-wrapper">

                <Link className="footer-nav-single" to={"/"}>
                    <div className="menu-wrapper">
                        <img src={home_menu} alt="Home" width="30px" />
                        <span className="nav-text">Home</span>
                    </div>
                </Link>

                <Link className="footer-nav-single" to={"/sportsbook"}>
                    <div className="menu-wrapper">
                        <img src={sports_menu} alt="Home" width="30px" />
                        <span className="nav-text">Sports</span>
                    </div>
                </Link>
                <Link className="footer-nav-single" to={"/home-game/all-game"}>
                    <div className="menu-wrapper">
                        <img src={casino_menu} alt="Home" width="30px" />
                        <span className="nav-text">Casino</span>
                    </div>
                </Link>

              <Link className="footer-nav-single" to={"/bonus"}>
                  <div className="menu-wrapper">
                        <img src={bonus_menu} alt="Home" width="30px" />
                      <span className="nav-text">Bonus</span>
                  </div>
              </Link>

            </div>
        </footer>

      
    </div>
  );
};

export default Footer;
